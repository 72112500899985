import React, { useState, useEffect } from "react"
import { Form, Row, Col, Button, Input } from "antd"
import { DefaultApprovalPathsClient } from "@api"
import { showError, showSuccess } from "@action"
import { navigate, Link } from "gatsby"
import { DeleteButton } from "lib"
import PathBoxList from "./PathBoxList"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

const SubmitForm = ({ id, data, options, isCopy = false }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  const addStep = arr => {
    if (arr) {
      return arr.map((item, index) => {
        return {
          ...item,
          appraisalRoleType:
            item.appraisalRoleType >= 100 ? 2 : item.appraisalRoleType,
          approvalRoleId:
            item.appraisalRoleType >= 100 ? item.appraisalRoleType - 100 : null,
          step: index,
        }
      })
    }
    return []
  }

  const onFinish = async values => {
    const newValues = {
      ...values,
      appraisalPaths: addStep(values.appraisalPaths),
    }
    try {
      setIsLoading(true)
      const client = new DefaultApprovalPathsClient()
      if (!id || isCopy) {
        await client.create(newValues)
        showSuccess("Create Success!")
      } else {
        await client.update(id, {
          id: id,
          ...newValues,
        })
        showSuccess("Edit Success!")
      }
      navigate("/admin_panel/approvalPath/")
    } catch (err) {
      showError(err)
      setIsLoading(false)
    }
  }
  const handleDelete = async () => {
    try {
      setIsLoading(true)
      const client = new DefaultApprovalPathsClient()
      await client.delete(id)
      showSuccess("Delete Success")
      navigate("/admin_panel/approvalPath/")
    } catch (err) {
      showError(err)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!!data) {
      const sortedList = data.appraisalPaths.sort((a, b) => a.step - b.step)
      const appraisalPaths = sortedList.map(item => ({
        ...item,
        appraisalRoleType:
          item.appraisalRoleType === 2
            ? item.approvalRoleId + 100
            : item.appraisalRoleType,
      }))

      form.setFieldsValue({ ...data, appraisalPaths: appraisalPaths })
    }
  }, [data])

  const colProps = { span: 8 }
  return (
    <DndProvider backend={HTML5Backend}>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col {...colProps}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please input name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col {...colProps}>
            <Form.Item
              label="Code"
              name="code"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please input name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <PathBoxList options={options} />
          </Col>
        </Row>
        <Row gutter={[12, 12]} justify="center" style={{ marginTop: "10px" }}>
          {data?.isCanDelete && (
            <Col>
              <DeleteButton
                disabled={isLoading}
                onClick={() => {
                  handleDelete()
                }}
              />
            </Col>
          )}
          <Col>
            <Button
              loading={isLoading}
              type="danger"
              shape="round"
              onClick={() => {
                form.submit()
              }}
            >
              {!!id && !isCopy ? "Save" : "Add"}
            </Button>
          </Col>
          <Col>
            <Link to="/admin_panel/approvalPath/">
              <Button shape="round" disabled={isLoading}>
                Cancel
              </Button>
            </Link>
          </Col>
        </Row>
      </Form>
    </DndProvider>
  )
}

export default SubmitForm
