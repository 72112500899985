import React, { useState, useEffect } from "react"
import Seo from "@components/seo"
import MainLayout from "@components/main_layout"
import { message } from "antd"
import {
  DefaultApprovalPathsClient,
  CommentDefinitionClient,
  ApprovalRoleClient,
} from "@api"
import { navigate } from "gatsby"
import { PageHeader } from "lib"
import { SubmitForm } from "@components/configuartion/ApprovalPath"

const ApprovalPathConfiguration = ({ location }) => {
  const { state = {} } = location
  const [record, setRecord] = useState(null)
  const [options, setOptions] = useState(null)

  useEffect(() => {
    const getName = async () => {
      const client = new DefaultApprovalPathsClient()
      const result = await client.get2(state.id).catch(() => {
        message.error("System Error occur")
        navigate("/")
        return {}
      })

      setRecord({
        ...result,
        appraisalPaths: result.defaultApprovalSteps.map(item => {
          return {
            ...item,
            comments: item.defaultApprovalStepComments.map(
              comment => comment.commentDefinition.id
            ),
          }
        }),
      })
    }
    const getOptions = async () => {
      const commentClient = new CommentDefinitionClient()
      const commentResult = await commentClient
        .get(true, "id", true, 0, 0)
        .catch(err => {
          console.log(err)
          return { list: [] }
        })
      const roleClient = new ApprovalRoleClient()
      const roleResult = await roleClient.get(0, 0, "name", true).catch(err => {
        console.log(err)
        return { content: { approvalRoleGroup: [] } }
      })

      setOptions({
        roleOptions: roleResult.content.approvalRoleGroup,
        commentsOptions: commentResult.list,
      })
    }

    if (!!state?.id) getName()
    getOptions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <MainLayout>
      <Seo title="Approval Path Configuration" />
      <PageHeader title="Approval Path Configuration" />
      {options ? (
        <SubmitForm
          id={state?.id}
          data={record}
          options={options}
          isCopy={state?.isCopy}
        />
      ) : null}
    </MainLayout>
  )
}

export default ApprovalPathConfiguration
